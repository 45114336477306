/**
 * Generated using svgr with the react-native option and a custom template
 * from the following file:
 * https://www.figma.com/file/Qul4lqXRzrrmmgHFgRyrmB/Brand?node-id=1401%3A1457
 */

import * as React from 'react';
type Props = { color?: string };

export const CloseIcon: React.FC<Props> = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        className="path"
        fill={color || 'white'}
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      />
    </svg>
  );
};
