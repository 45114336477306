import 'typeface-ibm-plex-sans/index.css';
import 'typeface-work-sans/index.css';
import '../styles/index.scss';

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

import { CookieBanner } from './cookie-banner';
import { Header } from './header';

export interface LayoutBrandData {
  id: string;
  name: string;
  favicon: string;
  cookiePolicyURL: string;
}

export type QueryData = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

type LayoutProps = {
  brand: LayoutBrandData;
};

export const Layout: React.FC<LayoutProps> = ({ children, brand }) => {
  const data = useStaticQuery<QueryData>(graphql`
    fragment LayoutBrandFragment on BrandsJson {
      id
      name
      favicon
      cookiePolicyURL
    }

    query SiteLayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <CookiesProvider>
      <Helmet
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: brand.favicon,
          },
        ]}
      />
      <div
        className={`theme-${brand.id}`}
        style={{ minHeight: '100vh', position: 'relative' }}
      >
        <CookieBanner url={brand.cookiePolicyURL} />
        <Header
          siteTitle={data.site.siteMetadata.title}
          brandName={brand.name}
          logo={brand.favicon}
        />

        <main>{children}</main>
      </div>
    </CookiesProvider>
  );
};
