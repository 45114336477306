import '../styles/cookie-banner.scss';

import { addDays } from 'date-fns';
import React from 'react';
import { useCookies } from 'react-cookie';

import { CloseIcon } from './icons/CloseIcon';

const COOKIE_CONSENT_NAME = 'cookieConsent';

type CookieBannerProps = {
  url: string;
};

export const CookieBanner: React.FC<CookieBannerProps> = ({ url }) => {
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_NAME]);

  const acceptCookies = () => {
    const cookieExpiry = addDays(new Date(), 90);
    setCookie(COOKIE_CONSENT_NAME, 'cookies accepted', {
      path: '/',
      expires: cookieExpiry,
    });
  };

  return (
    <div>
      {!cookies[COOKIE_CONSENT_NAME] && (
        <div className="cookie-banner">
          <p className="cookie-banner__text">
            By using our site, you accept our{' '}
            <a href={url} target="_blank" rel="noreferrer noopener">
              use of cookies
            </a>
            .
          </p>
          <button
            type="button"
            className="button--close"
            aria-label="Close cookie banner"
            onClick={acceptCookies}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
};
