import '../styles/header.scss';

import React from 'react';

type HeaderProps = {
  siteTitle?: string;
  brandName: string;
  logo: string;
};

export const Header: React.FC<HeaderProps> = ({
  siteTitle,
  brandName,
  logo,
}) => {
  return (
    <header className="header">
      <div className="container header__content">
        <img src={logo} alt={brandName} className="header__logo" />
        <h1 className="header__title heading3">
          {brandName} <span className="header__subtitle">{siteTitle}</span>
        </h1>
      </div>
    </header>
  );
};

Header.defaultProps = {
  siteTitle: ``,
};
